<template>
  <div>
    <CRow>
      <CCol lg="6">
        <CCardBody>
          <CDataTable
                  :items="apiDatas"
                  :fields="fields"
                  column-filter
                  table-filter
                  items-per-page-select
                  :items-per-page="50"
                  sorter
                  pagination
                  clickable-rows
                  @row-clicked="rowClicked"
                  @page-change="pageChange"
          >
            <template #actions="{ item }">
              <td>
                <CButton @click="removeGrs(item)" color="primary">Удалить</CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCol>
      <CCol lg="6">
        <CCard>
          <CCardHeader>
            <strong>Добавить ГРС</strong>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="createGrs()">
              <CInput
                      type="hidden"
                      name="action"
                      v-model="form.action"
                      required
              />
              <CInput
                      label="Введите название ГРС"
                      v-model="form.grs_name"
                      required
              />

                <label>Выберите область</label>
                <model-list-select :list="regionDatas"
                                   v-model="form.region_id"
                                   option-value="value"
                                   option-text="label"
                                   placeholder="Выберите область">
                </model-list-select>

                <br>
                <label>Выберите пользователя</label>
                <model-list-select :list="operatorDatas"
                                   v-model="form.operator_id"
                                   option-value="value"
                                   option-text="label"
                                   placeholder="Выберите пользователя">
                </model-list-select>
                <br>
              <CButton type="submit" size="sm" color="primary"><CIcon name="cil-check-circle"/> Добавить</CButton>
            </CForm>
          </CCardBody>
          <CCardFooter>
          </CCardFooter>
        </CCard>
      </CCol>

    </CRow>
  </div>
</template>

<script>
  import axios from 'axios'
  import { ModelListSelect } from 'vue-search-select'
  import 'vue-search-select/dist/VueSearchSelect.css'


  const fields = [
    {
      key: "name",
      label: "Название ГРС",
      _style: "min-width:100%;"
    },
    {
      key: "region_name",
      label: "Область",
      _style: "min-width:100%;"
    },
    {
      key: "operator_name",
      label: "Оператор",
      _style: "min-width:100%;"
    },
    // {
    //   key: "actions",
    //   label: "Действия",
    //   _style: "min-width:1%;",
    //   sorter: false,
    //   filter: false
    // },
  ];

  export default {
    name: 'Grses',
    data: function() {
      return {
        visibleLiveDemo: false,
        apiDatas: [],
        regionDatas: [],
        operatorDatas: [],
        itemActive: 0,
        tableFields: ['id', 'name', 'region_name', 'operator_name'],
        form: {
          action: 'add_grs',
          grs_name: "",
          region_id: "",
          operator_id: ""
        },
        fields,
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler (route) {
          if (route.query && route.query.page) {
            this.activePage = Number(route.query.page)
          }
        }
      }
    },
    beforeRouteLeave(to, from, next) {
      to.meta.gid = this.itemActive;
      next()
    },
    methods: {
      getDataFromApi () {
        axios
          .get(`${axios.defaults.baseURL}?action=get_allgrses`)
          .then((response) => {
            this.apiDatas = response.data
          })

      },
      createGrs () {

        let bodyFormData = new FormData();

        bodyFormData.append('action', this.form.action);
        bodyFormData.append('grs_name', this.form.grs_name);
        bodyFormData.append('region_id', this.form.region_id);
        bodyFormData.append('operator_id', this.form.operator_id);

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then((res) => {

            this.form.grs_name = "";
            this.form.region_id = "";
            this.form.operator_id = "";
            //Perform Success Action
            this.getDataFromApi()
          })
          .catch((error) => {
            console.error(error)
            // error.response.status Check status code
          }).finally(() => {
          // this.getDataFromApi()
        });
      },
      removeGrs (item) {

        let bodyFormData = new FormData();

        bodyFormData.append('action', "remove_grs");
        bodyFormData.append('grs_id', item.id);

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then(() => {
            //Perform Success Action
            this.getDataFromApi()
          })
          .catch((error) => {
            console.error(error)
            // error.response.status Check status code
          }).finally(() => {
          // this.getDataFromApi()
        });
      },
      rowClicked (item, index) {
        this.itemActive = item.id;
        this.$router.push({path: `/grses/${index}`})
      },
      pageChange (val) {
        this.$router.push({ query: { page: val }})
      }
    },
    mounted() {
      axios
        .get(`${axios.defaults.baseURL}?action=get_allgrses`)
        .then((response) => {
          response.data.map((el) => {
            this.apiDatas.push({
              id: el.id,
              name: el.name,
              region_name: el.region_name,
              operator_name: el.operator_name
            })
          });
          // this.apiDatas = response.data;
          // console.log(responce.data)
        });
      axios
        .get(`${axios.defaults.baseURL}?action=get_regions`)
        .then((response) => {
          response.data.map((el) => {
            this.regionDatas.push({
              value: el.id,
              label: el.name
            })
          });
        })
      axios
        .get(`${axios.defaults.baseURL}?action=get_operators`)
        .then((response) => {
          response.data.map((el) => {
            this.operatorDatas.push({
              value: el.id,
              label: el.name
            })
          });
        })
    },
    components: {
      ModelListSelect
    }
  }
</script>
